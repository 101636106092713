@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* fix Mui Table Pagination component margins issue by bootstrap */
.MuiTablePagination-root p {
  margin: 0px;
}
/* remove autofill colors on chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
.Circular-Progress {
  text-align: center;
  margin-top: 15%;
}
.logout-box {
  position: unset !important;
}
.category-list {
  display: flex;
}
.category-list li:not(:last-child) {
  margin-right: 10px;
}
.category-list li {
  padding: 5px 15px;
  background-color: #ccc;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .Circular-Progress {
    text-align: center;
    margin-top: 70%;
  }
}
/*========Remove up and down arrows from input type number==========*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/*=================================================================*/

.language-item {
  cursor: pointer;
  text-transform: capitalize;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.language-item:hover {
  cursor: pointer;
  color: #87c326;
}

.exists-data {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 5px;
}

.exists-data.selected {
  background-color: #87c326;
}
